import React, { useRef, useEffect, useCallback } from "react"
import styled from "styled-components"
import lottie, { AnimationItem } from "lottie-web"

import DelayLink from "../components//DelayLink"
import { getTranslatedUrl } from "../utils/urls"

import SplashLottieData from "../../static/lotties/landing-lottie.json"

const Wrapper = styled.div`
  overflow: hidden;
  padding: 30px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all ease 300ms;
  opacity: 0;
`

const LogoContainer = styled.div`
  transform: translateX(25px);
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 20px 1fr 50px;
  align-items: flex-end;
  width: 500px;
  max-width: 100%;
`

const Logo = styled.img`
  grid-row: 1;
  grid-column: 1 / 3;
  padding-bottom: 20px;
`

const LottieContainer = styled.div`
  grid-row: 1;
  grid-column: 1 / 4;
`

const LinksContainer = styled.div`
  margin-top: 100px;
  display: flex;
  align-items: center;
  font-family: "Avenir LT Std";
  font-size: 10pt;
  letter-spacing: 0.48em;

  span {
    margin: 0 15px;
  }
`

const Home = (): JSX.Element => {
  const lottieRef = useRef<AnimationItem | null>(null)
  const lottieContainerRef = useRef<HTMLDivElement | null>(null)
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    let timeout = 0

    const init = (): void => {
      if (wrapperRef.current) {
        wrapperRef.current.style.opacity = "1"
      }

      if (lottieContainerRef.current) {
        lottieRef.current = lottie.loadAnimation({
          container: lottieContainerRef.current,
          loop: false,
          autoplay: false,
          animationData: SplashLottieData,
          rendererSettings: {
            preserveAspectRatio: "xMaxYMax",
          },
        })

        lottieRef.current.setSpeed(2)

        timeout = window.setTimeout(() => {
          if (lottieRef.current) {
            lottieRef.current.play()
          }
        }, 500)
      }
    }

    const destroy = (): void => {
      lottieRef.current?.destroy()
    }

    init()

    return (): void => {
      window.clearTimeout(timeout)
      destroy()
    }
  }, [])

  const handleLinkClick = useCallback(() => {
    if (lottieRef.current) {
      lottieRef.current.setDirection(-1)
      lottieRef.current.setSpeed(10)
      lottieRef.current.play()
    }

    window.setTimeout(() => {
      if (wrapperRef.current) {
        wrapperRef.current.style.opacity = "0"
      }
    }, 500)
  }, [])

  return (
    <Wrapper ref={wrapperRef}>
      <LogoContainer>
        <Logo src="/images/logo.svg" alt="Film Talents Logo" />
        <LottieContainer ref={lottieContainerRef} />
      </LogoContainer>

      <LinksContainer>
        <DelayLink
          to={getTranslatedUrl("/featured/", "fr")}
          onClick={handleLinkClick}
        >
          FR
        </DelayLink>
        <span>/</span>
        <DelayLink
          to={getTranslatedUrl("/featured/", "en")}
          onClick={handleLinkClick}
        >
          EN
        </DelayLink>
      </LinksContainer>
    </Wrapper>
  )
}

export default Home
